import { PublicEnvironment } from "~/environmentSchema";
type Props = PublicEnvironment;

declare global {
  interface Window {
    ENV: Props;
  }
}

/**
 * Injects public environment variables into the client.
 * @param props
 * @constructor
 */
export default function PublicEnv(props: Props) {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(props)}`,
      }}
    />
  );
}

/**
 * Safely returns public environment variables for the client.
 * @param key
 */
export function getPublicEnv<T extends keyof Props>(key: T): Props[T] {
  if (typeof window !== "undefined" && !window.ENV) {
    throw new Error(
      `Missing the <PublicEnv /> component at the root of your app.`
    );
  }

  return window.ENV[key];
}
